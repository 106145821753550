import React from 'react';

import {
  FormControl,
  FormControlLabel,
  Box,
  FormHelperText,
  Switch,
} from '@mui/material';

export default function EveFSRestrictedField({ value, setValue, setChanged }) {
  return (
    <Box>
      <FormControl fullWidth>
        <FormControlLabel
          control={
            <Switch
              checked={value}
              onChange={() => {
                setChanged();
                setValue(!value);
              }}
            />
          }
          label='Restrict EverywhereFS '
        />
        <FormHelperText id='evefs-restricted-helper-text'>
          Restrict EverywhereFS persistence replication to only CloudBender
          systems that are attached to the project owner's account.
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
