import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  ScopedCssBaseline,
  Container,
  Grid,
  Paper,
  Toolbar,
  Stack,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
  Tooltip,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ArrowBack } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import theme from '../../../theme';
import ErrorSnackBar from '../../shared/ErrorSnackBar';
import {
  fetchProjects,
  createProject,
  updateProject,
} from '../../../actions/projects';
import ProjectNameField from './ProjectNameField';

import { clearErrorMessage } from '../../../actions/errorMessage';

import CopyCredentialsField from './CopyCredentialsField';
import OwnedResourcesOnlyField from './OwnedResourcesOnlyField';
import EveFSRestrictedField from './EveFSRestrictedField';
import InteractiveDisabledField from './InteractiveDisabledField';
import NonRegionalOutputDisabledField from './NonRegionalOutputDisabledField';

export default function ProjectForm() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const api_error = useSelector((state) => state.errorMessage);
  const projects = useSelector((state) => state.projects);
  const user = useSelector((state) => state.user);

  const sp = new URLSearchParams(location.search);

  useEffect(() => {
    window.gtag('event', `project_form_open`, {
      customer_uuid: user.id,
    });
  }, [user]);

  useEffect(() => {
    return () => {
      dispatch(clearErrorMessage());
    };
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (projects === null) {
        dispatch(fetchProjects());
        return;
      }
    })();
  }, [dispatch, projects]);

  let project;
  if (projects && sp.get('id')) {
    project = projects.find((project) => project.id === sp.get('id'));
  }
  const [pristine, setPristine] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(project?.name || '');
  const [copy_credentials, setCopyCredentials] = useState(false);
  const [owned_resources_only, setOwnedResourcesOnly] = useState(
    project?.owned_resources_only || false
  );
  const [evefs_restricted, setEvefsRestricted] = useState(
    project?.evefs_restricted || false
  );
  const [interactive_disabled, setInteractiveDisabled] = useState(
    project?.interactive_disabled || false
  );
  const [non_regional_output_disabled, setNonRegionalOutputDisabled] = useState(
    project?.non_regional_output_disabled || false
  );
  const [name_error, setNameError] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      if (project) {
        window.gtag('event', `project_edited`, {
          customer_uuid: user.id,
          id: project.id,
        });
        await dispatch(
          updateProject(project.id, {
            name,
            owned_resources_only,
            evefs_restricted,
            interactive_disabled,
            non_regional_output_disabled,
          })
        );
      } else {
        window.gtag('event', `project_submitted`, {
          customer_uuid: user.id,
        });

        try {
          await dispatch(
            createProject({
              name,
              copy_credentials,
              owned_resources_only,
              evefs_restricted,
              interactive_disabled,
              non_regional_output_disabled,
            })
          );
          window.gtag('event', `project_created`, {
            customer_uuid: user.id,
          });
        } catch (error) {
          window.gtag('event', `project_errored`, {
            customer_uuid: user.id,
          });
          throw error;
        }
      }

      history.push(`/projects`);
    } catch (error) {
      setLoading(false);
    }
  };

  const not_enough_credits = Boolean(
    !user.cloudbender_enabled && user.credits <= 0 && !user.billing_account_id
  );

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline>
        <Container>
          <Paper sx={{ display: 'flex', width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <Paper sx={{ display: 'flex', width: '100%' }} elevation={2}>
                  <Toolbar>
                    <Stack spacing={2} direction='row' alignItems='center'>
                      <IconButton
                        onClick={() => history.goBack()}
                        disabled={loading}
                      >
                        <ArrowBack />
                      </IconButton>
                      <Typography variant='h5'>
                        {project
                          ? `Edit ${project.name}`
                          : `Create New Project`}
                      </Typography>
                    </Stack>
                  </Toolbar>
                </Paper>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Box
                  padding='15px'
                  sx={{
                    maxWidth: 400,
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <ProjectNameField
                        value={name}
                        error={name_error}
                        edit={Boolean(project)}
                        setValue={setName}
                        setError={setNameError}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <OwnedResourcesOnlyField
                        value={owned_resources_only}
                        setValue={setOwnedResourcesOnly}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    {owned_resources_only ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <EveFSRestrictedField
                          value={evefs_restricted}
                          setValue={setEvefsRestricted}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                    <Grid item xs={12} md={12} lg={12}>
                      <InteractiveDisabledField
                        value={interactive_disabled}
                        setValue={setInteractiveDisabled}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                      <NonRegionalOutputDisabledField
                        value={non_regional_output_disabled}
                        setValue={setNonRegionalOutputDisabled}
                        setChanged={() => setPristine(false)}
                      />
                    </Grid>
                    {!Boolean(project) ? (
                      <Grid item xs={12} md={12} lg={12}>
                        <CopyCredentialsField
                          value={copy_credentials}
                          setValue={setCopyCredentials}
                          setChanged={() => setPristine(false)}
                        />
                      </Grid>
                    ) : undefined}
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Divider />
                <Toolbar>
                  <Stack spacing={2} direction='row'>
                    <Tooltip
                      title={not_enough_credits ? 'Not Enough Credits' : ''}
                    >
                      <span>
                        <LoadingButton
                          variant='contained'
                          color='brand'
                          onClick={handleSubmit}
                          loading={loading}
                          disabled={
                            pristine ||
                            Boolean(name_error) ||
                            !Boolean(name) ||
                            not_enough_credits
                          }
                        >
                          Submit
                        </LoadingButton>
                      </span>
                    </Tooltip>

                    <Button
                      variant='outlined'
                      onClick={() => history.goBack()}
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Toolbar>
              </Grid>
            </Grid>
          </Paper>
          <ErrorSnackBar message={api_error} />
        </Container>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}
